import React from "react";

const BookingForm = () => {

  return (
     <div className="bookingsPage">
       <h1>Request a Booking</h1>
       <h3>Please use the form below to request a booking with us. Please note that this will not be finalised until we have confirmed it with you, and this will be subject to availability. </h3>
        <p className="iframeContainer"><iframe src="https://youreontime-booking.com/metalmagicpiercings?locationid=5554&staffid=72963" title="bookings" width="400px" height="250px"></iframe></p>         
     </div>      
  );
};

export default BookingForm;
