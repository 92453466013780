import React from "react";
import errorcorn from "../../src/img/errorcorn.png"

const ErrorPage = () => {

return (
    <div className="errorPage">
        
    <h3 className="centre">Sorry, something went wrong. Please click the logo to return to the home page.</h3>
    <p className="centre"><img src={errorcorn} alt="unicorn farting rainbows" /></p>
    </div>
)

};

export default ErrorPage;